const initialState = {
  fetchingAllUsers: false,
  fetchingProxy: false,
  proxies: [],
  allUsers: [],
  allUsersWithoutMandating: [],
  allMandataries: [],
  otherUsers: [],
};

const reducer = (state = initialState, action = {}) => {
  let newState = { ...state };
  switch (action.type) {
    case LOAD_USERS_START:
      newState.fetchingAllUsers = true;
      return newState;
    case LOAD_USERS_END:
      const users = action.users;
      newState.allUsers = Array.from(new Set(users.map((a) => a.userId))).map(
        (id) => {
          return users.find((a) => a.userId === id);
        }
      );
      newState.fetchingAllUsers = false;
      return newState;
    case RESET_USERS:
      newState.allUsers = [];
      newState.fetchingAllUsers = false;
      return newState;
    case RESET_PROXY:
      newState.proxies = [];
      newState.fetchingProxy = false;
      return newState;
    case SEND_PROXY_START:
    case GET_PROXIES_START:
    case DELETE_PROXY_START:
      newState.fetchingProxy = true;
      return newState;
    case GET_PROXIES_END:
      newState.fetchingProxy = false;
      newState.proxies = action.proxies;

      const newUsersWithoutMandating = [];
      const newMandataries = [];
      const newOtherUsers = [];

      state.allUsers.map((user) => {
        let isMandatating = false;
        let isMandatary = false;
        action.proxies.forEach((proxy) => {
          if (proxy?.mandatary?.userId === user?.userId) {
            isMandatary = true;
          }
          if (proxy?.mandating?.userId === user?.userId) {
            isMandatating = true;
          }
        });

        if (!isMandatating) {
          newUsersWithoutMandating.push(user);
          if (!isMandatary) {
            newOtherUsers.push(user);
          }
        }
        if (isMandatary) {
          newMandataries.push(user);
        }
      });
      newState.otherUsers = newOtherUsers;
      newState.allUsersWithoutMandating = newUsersWithoutMandating;
      newState.allMandataries = newMandataries;

      return newState;

    default:
      return state;
  }
};

export const LOAD_USERS_START = "LOAD_USERS_START";
export const LOAD_USERS_END = "LOAD_USERS_END";
export const RESET_USERS = "RESET_USERS";
export const SEND_PROXY_START = "SEND_PROXY_START";
export const GET_PROXIES_START = "GET_PROXIES_START";
export const GET_PROXIES_END = "GET_PROXIES_END";
export const DELETE_PROXY_START = "DELETE_PROXY_START";
export const RESET_PROXY = "RESET_PROXY";
export default reducer;
