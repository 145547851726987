const initialState = {
  showAddSession: false,
  showAddAdmin: false,
  showAddVote: false,
  lastAction: new Date(),
  fetchingSession: false,
  fetchingVotes: false,
  fetchingGetVotes: false,
  selectedSession: null,
  selectedVote: null,
  totalSessions: false,
  sessions: [],
  votes: [],
  upload: false,
  getAllVotes: false
};

const reducer = (state = initialState, action = {}) => {
  let newState = { ...state };
  switch(action.type) {
    case 'ADD_SESSION_OPEN':
      if (action.payload) {
        return {
          ...state,
          showAddSession: action.payload
        }
      } else {
        return {
          ...state,
          showAddSession: true
        }
      }
    case 'ADD_ADMIN_OPEN':
      if (action.payload) {
        return {
          ...state,
          showAddAdmin: action.payload
        }
      } else {
        return {
          ...state,
          showAddAdmin: true
        }
      }
    case 'ADD_ADMIN_CANCEL':
      return {
        ...state,
        showAddAdmin: false
      }
    case 'ADD_SESSION_CANCEL':
      return {
        ...state,
        showAddSession: false
      }
    case 'ADD_SESSION_VOTE_OPEN':
      return {
        ...state,
        showAddVote: action.payload || true
      }
    case 'ADD_SESSION_VOTE_CANCEL':
      return {
        ...state,
        showAddVote: false
      }
    case "ADD_SESSION_PROGRESS":
      return {
        ...state,
        upload: action.payload
      }
    case 'ADD_SESSION_START':
    case 'DELETE_SESSION_START':
    case 'GET_SESSIONS_START':
    case 'EDIT_SESSION_START':
    case 'EDIT_ADMIN_START':
      return {
        ...state,
        fetchingSession: true,
      }
    case 'DELETE_SESSION_END':
    case 'EDIT_SESSION_END':
    case 'EDIT_ADMIN_END':
      return {
        ...state,
        fetchingSession: false,
        lastAction: new Date()
      }
    case 'GET_SESSIONS_END':
      return {
        ...state,
        fetchingSession: false,
        sessions: action.payload.sessions,
        totalSessions: action.payload.totalSessions
      }
    case "START_SESSION_END":
      newState.selectedSession = { ...state.selectedSession, status : 1}
      return newState;
    case "END_SESSION_END":
      newState.selectedSession = { ...state.selectedSession, status : 2}
      return newState;
    case 'ADD_SESSION_END':
      return {
        ...state,
        fetchingSession: false,
        showAddSession: false,
        upload: false,
        lastAction: new Date()
      }
    case 'ADD_SESSION_ERROR':
      return {
        ...state,
        upload: false,
      }
    case 'SELECT_SESSION':
      return {
        ...state,
        selectedSession: action.payload,
        votes: []
      }
    case 'SELECT_SESSION_VOTE':
      return {
        ...state,
        selectedVote: action.payload
      }
    case "DELETE_VOTE_END":
      const newVotes = state.votes.filter(vote => vote.id !== action.payload);
      return {
        ...state,
        fetchingGetVotes: false,
        votes: newVotes
      }
    case 'GET_SESSION_VOTES_START':
      return {
        ...state,
        fetchingGetVotes: true,
        getAllVotes: false
      }
    case 'GET_SESSION_VOTES_END':
      return {
        ...state,
        fetchingGetVotes: false,
        votes: action.payload
      }
    case "ADD_VOTE_START":
    case "EDIT_VOTE_START":
      return {
        ...state,
        fetchingVotes: true
      }
    case "ADD_VOTE_END":
    case "EDIT_VOTE_END":      
      return {
        ...state,
        showAddVote: false,
        fetchingVotes: false,
        upload: false,
        getAllVotes: true
      }
    case "START_SESSION_VOTE_END":
      const startVotes = [...state.votes]
      startVotes.forEach((sv)=>{
        if(sv.id === action.payload) {
          sv.status = 1;
        }
      })

      return {
        ...state,
        votes: startVotes
      }
    case "END_SESSION_VOTE_END":
      const endVotes = [...state.votes]
      endVotes.forEach((ev)=>{
        if(ev.id === action.payload) {
          ev.status = 2;
        }
      })

      return {
        ...state,
        votes: endVotes
      }
    default:
      return state;
  }
}

export default reducer;