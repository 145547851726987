// React
import React from "react";

// i18n
import { FormattedMessage } from "react-intl";
import messages from "../containers/Layout/Topbar/messages";

const initialState = {
  title : <FormattedMessage {...messages.voteAndSession} />,
};

const reducer = (state = initialState, action = {}) => {
  let newState = { ...state };
  switch (action.type) {
    case 'VOTE_AND_SESSION':
      newState.title = <FormattedMessage {...messages.voteAndSession} />;
    return newState;
    case 'DELIBERATION':
      newState.title = <FormattedMessage {...messages.deliberation} />;
    return newState;
    case 'VOTE':
      newState.title = <FormattedMessage {...messages.vote} />;
    return newState;
    case 'PROXY':
      newState.title = <FormattedMessage {...messages.proxy} />;
    return newState;

    default:
      return state;
  }
}

export default reducer;