const initialState = {
  fetchingMySession: false,
  fetchingVotes: false,
  selectedMySession: null,
  mySessions: [],
  mySessionPage: "home", // home, document
  selectedVote: null,
  selectedProxy: null,
  votes: [],
  votesByProxies: []
};

const reducer = (state = initialState, action = {}) => {
  switch(action.type) {
    case 'GET_MY_SESSIONS_START':
      return {
        ...state,
        fetchingMySession: action.payload
      }
    case 'GET_MY_SESSIONS_END':
      return {
        ...state,
        fetchingMySession: false,
        mySessions: action.payload
      }
    case 'SELECT_MY_SESSION':
      return {
        ...state,
        selectedMySession: action.payload
      }
    case 'SELECT_MY_SESSION_PAGE':
      return {
        ...state,
        mySessionPage: action.payload
      }   
    case 'GET_MY_SESSION_VOTES_END':
      const newState = {...state}
      newState.fetchingVotes = false;
      newState.votes = action.payload.votes;
      newState.votesByProxies = action.payload.proxies;
      const newStatus = action.payload?.session?.status;
      if(state.selectedMySession?.status !== newStatus && Number.isInteger(newStatus)) {
        newState.selectedMySession = {...state.selectedMySession, status : newStatus};
      }
      if(state.selectedVote) {
        const newSelectedVote = action.payload?.votes?.find((vote)=>vote.id==state.selectedVote.id);
        if(newSelectedVote){
          newState.selectedVote = newSelectedVote;
        }
      }
      return newState;
    case 'SELECT_MY_SESSION_VOTE':
      return {
        ...state,
        selectedVote: action.payload
      }
    case 'SELECT_MY_PROXY_VOTE':
      return {
        ...state,
        selectedVote: action.payload.vote,
        selectedProxy: action.payload.proxy
      }
    case 'SEND_SESSION_VOTE_RESULT_END':
      const result = action.payload;
      const tmpVotes =  [...state.votes]
      const tmpVote = tmpVotes.find((vote) => vote.id == result?.vote?.id);
      if(tmpVote) {
        const i =  tmpVote?.results?.findIndex((r)=> {
          return (r?.by?.userId || r?.by) == result?.by
        });
        if(i >= 0) {
          tmpVote.results[i] = action.payload;
        }
        else {
          tmpVote.results.push(action.payload);
        }
        if(result?.by == (result?.createdBy || result?.updatedBy)) {
          tmpVote.userResult = action.payload;
        }
      }
      return {
        ...state,
        votes: tmpVotes

      }
    case 'CLEAR_MY_SESSION_VOTES':
      return {
        ...state,
        votes: []
      }
    default:
      return state;
  }
}

export default reducer;