import toast from "react-hot-toast";

const initialState = {
  currentAlertsIds: [],
};

const createAlert = (content, options, type) => {
  let alertId;
  switch (type) {
    case "blank":
      alertId = toast(content, options);
      break;
    case "success":
      alertId = toast.success(content, options);
      break;
    case "error":
      alertId = toast.error(content, options);
      break;
    case "loading":
      alertId = toast.loading(content, options);
      break;
    case "custom":
      alertId = toast.custom(content, options);
      break;
    default:
      alertId = toast(content, options);
  }
  return alertId;
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    // Create don't need to have id,
    // but for update, need to have the id of the alert.
    // the Id is to be filled in the options
    // @see https://react-hot-toast.com/docs/toast
    case "CREATE_AND_UPDATE_ALERT":
      const data = action.payload;
      const content = data.content;
      const options = data.options;
      const type = data.type;
      const id = createAlert(content, options, type);
      return {
        ...state,
        currentAlertsIds: [...state.currentAlertsIds, id],
      };
    case "REMOVE_ALERT":
      const alertId = action.payload.id;
      toast.dismiss(alertId);
      return {
        ...state,
        currentAlertsIds: state.currentAlertsIds.filter((id) => id !== alertId),
      };
    case "REMOVE_ALL_ALERTS":
      toast.dismiss();
      return {
        ...state,
        currentAlertsIds: [],
      };
    default:
      return state;
  }
};

export default reducer;
