const initialState = {
  votes: [],
  voteSelected: {},
  voteError: {},
  fetchingVote: false,
  upload: false
};

const reducer = (state = initialState, action = {}) => {
  let newState = { ...state };
  switch (action.type) {
    case LOAD_VOTE_SELECTED_END:
      newState.voteSelected = action.payload;
      newState.fetchingVote = false;
      return newState;

    case LOAD_VOTE_ERROR:
      newState.voteError = action.payload;
      newState.fetchingVote = false;
      return newState;

    case LOAD_VOTE_END:
      newState.votes = action.payload;
      newState.fetchingVote = false;
      return newState;
      
    case "ADD_VOTE_PROGRESS":
    case "ADD_VOTE_START":
      newState.upload = action.payload;
      return newState;

    case "ADD_VOTE_END":
      newState.fetchingVote = false;
      newState.upload = false;
      return newState;

    case RESET_VOTE_SELECTED:
      newState.voteSelected = {};
      return newState;

    default:
      return state;
  }
};

export const CREATE_VOTE_START = "CREATE_VOTE_START";
export const DELETE_VOTE_START = "DELETE_VOTE_START";
export const EDIT_VOTE_START = "EDIT_VOTE_START";
export const LOAD_VOTE_START = "LOAD_VOTE_START";
export const LOAD_VOTE_SELECTED_END = "LOAD_VOTE_SELECTED_END";
export const LOAD_VOTE_END = "LOAD_VOTE_END";
export const LOAD_VOTE_ERROR = "LOAD_VOTE_ERROR";
export const RESET_VOTE_SELECTED = "RESET_VOTE_SELECTED";
export default reducer;
