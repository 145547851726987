const initialState = {
  vote: null
}

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case "SELECT_VOTE_RESULTS":
      return {
        ...state,
        vote: action.payload
      }
    default:
      return state;
  }
}

export default reducer;