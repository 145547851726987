import { defineMessages } from "react-intl";
export default defineMessages({
  voteAndSession: {
    id: "a2vote.title.voteAndSession",
    defaultMessage: "Vote and Session",
  },
  deliberation: {
    id: "a2vote.title.deliberation",
    defaultMessage: "Deliberation",
  },
  vote: {
    id: "a2vote.title.vote",
    defaultMessage: "Vote",
  },
  proxy: {
    id: "a2vote.title.proxy",
    defaultMessage: "Proxy",
  },
  disconnect: {
    id: "a2vote.title.disconnect",
    defaultMessage: "Disconnect",
  },
})