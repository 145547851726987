const initialState = {
  userFront: {},
  fetchingLogin: false,
  failedLogin: false,
  failedCheckLogin: false,
  resetPwdIsSend: false,
  fetchingUpdateUser: false,
  failedUpdateUser: false,
  twoAuth: false,
  fetchingTwoAuth: false,
  closePage: false,
};

const reducer = (state = initialState, action = {}) => {
  let newState = { ...state };
  switch (action.type) {
    case "FETCHING_LOGIN":
    case "LOGIN_START":
      newState.fetchingLogin = true;
      newState.failedLogin = false;
      newState.failedCheckLogin = false;
      return newState;
    case "LOGIN_END_SUCCESS":
      newState.fetchingLogin = false;
      newState.failedLogin = false;
      newState.userFront = action.payload;
      return newState;

    case "LOGOUT_SUCCESS":
      newState.userFront = initialState.userFront;
      newState.fetchingLogin = false;
      newState.failedLogin = false;
      newState.failedCheckLogin = false;
      return newState;

    case "FAILED_LOGIN":
      newState.fetchingLogin = false;
      newState.failedLogin = true;
      return newState;

    case "FAILED_CHECK_LOGIN":
      newState.fetchingLogin = false;
      newState.failedCheckLogin = true;
      return newState;

    case "CHECK_LOGIN_SUCCESS":
      newState.fetchingLogin = false;
      newState.failedLogin = false;
      newState.failedCheckLogin = false;
      newState.userFront = action.payload;
      return newState;
    case "RESET_PASSWORD_SUCCESS":
      newState.resetPwdIsSend = true;
      return newState;
    case "UPDATE_USER_START":
      newState.fetchingUpdateUser = true;
      newState.failedUpdateUser = false;
      return newState;
    case "UPDATE_USER_SUCCESS":
      newState.fetchingUpdateUser = false;
      newState.failedUpdateUser = false;
      newState.userFront = action.payload;
      return newState;
    case "UPDATE_USER_ERROR":
      newState.fetchingUpdateUser = false;
      newState.failedUpdateUser = true;
      return newState;
    case "CHECK_TOKEN_SUCCESS":
      newState.closePage = true;
      return newState;
    case "CHECK_2FA_START":
      newState.fetchingTwoAuth = true;
      return newState;
    case "CHECK_2FA_SUCCESS":
      newState.fetchingTwoAuth = false;
      newState.twoAuth = false;
      return newState;
    case "SHOW_2FA":
      newState.twoAuth = true;
      return newState;
    case "CLOSE_PAGE":
      newState.twoAuth = false;
      return newState;  
    default:
      return state;
  }
}

export default reducer;