import React from "react";
// Redux
import { Provider } from "react-redux";
import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import thunkMiddleware from "redux-thunk";
// i18n wrapper
import LanguageProvider from "utils/LanguageProvider";

// Reducers
import voteReducer from "state/createStore";
import languageReducer from "state/language";
import userListReducer from "state/userList";
import sessionReducer from "state/session";
import titleReducer from "state/title";
import userReducer from "state/user";
import mySessionReducer from "state/mySession";
import proxyReducer from "state/proxy";
import voteResultsReducer from "state/voteResults";
import alertReducer  from "state/alert";

// i18n Translations
import enTranslation from "./lang/en";
import frTranslation from "./lang/fr";
import { Toaster } from "react-hot-toast";

const withProvider = ({ element }) => {
  const rootReducer = combineReducers({
    languageReducer,
    mySession: mySessionReducer,
    session: sessionReducer,
    title: titleReducer,
    user: userReducer,
    userList: userListReducer,
    voteReducer,
    proxy: proxyReducer,
    voteResults: voteResultsReducer,
    alert: alertReducer
  });
  const composedEnhancer = compose(applyMiddleware(thunkMiddleware));
  const store = createStore(rootReducer, composedEnhancer);

  const messages = {
    en: enTranslation,
    fr: frTranslation,
  };

  return (
    <Provider store={store}>
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
      <link
        href="https://fonts.googleapis.com/css2?family=Roboto&family=Lato&family=Raleway&display=swap"
        rel="stylesheet"
      />
      <LanguageProvider messages={messages}>
        {element}
      </LanguageProvider>
      <Toaster />
    </Provider>
  );
};

export default withProvider;
